.main-container {
  margin: 10vw;
  text-align: center;
}

.brand-logo {
  max-width: 160px;
  margin: 0 auto;
}

.brand-logo img {
  width: 100%;
  height: auto;
}

.status-btn {
  border: none;
  color: white;
  display: inline-block;
  font-size: 16px;
  padding: 16px 58px;
  text-decoration: none;
}
