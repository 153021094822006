.flex-row {
  background-color: white;
  display: flex;
  justify-content: space-evenly;
  min-width: 1200px;
}

.box {
  margin-top: 60px;
  margin-bottom: 60px;;
  min-width: 452px;
  padding: 50px 50px 0 50px;
}

.box-l {
  background-color: rgb(242, 242, 241);
}

.box-r {
  text-align: center;
}

.qr-section {
  align-items: center;
  border: 5px solid;
  display: flex;
  justify-content: center;
  padding: 40px;
  margin: 0 auto;
  width: 220px;
}
