@font-face {
  font-family: 'BrandonTextWeb-Regular';
  src: url('../assets/fonts/BrandonTextWeb-Regular.woff') format('woff');
}

body {
  background-color: rgb(245, 245, 245);
  color: black;
  font-family: 'BrandonTextWeb-Regular';
}

.center-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
